import React, { useState } from "react";
import AWS from "aws-sdk"; // Importa el SDK de AWS
import { Amplify } from "aws-amplify";
import awsExports from '../../aws-exports';
import { fetchAuthSession } from '@aws-amplify/auth';
import "./Sync.css";
import MessageSync from "./MessageSync";


Amplify.configure(awsExports);

const Sync = ({ user }) => {
  const [loading, setLoading] = useState(false);  
  const [counter, setCounter] = useState(0); 
  const [showMessage, setShowMessage] = useState(false);

  const handleFullSync = async () => {
    setLoading(true); 
    setCounter(0);  
    setShowMessage(false);


    try {
      // Obtener las credenciales del usuario autenticado
      const session = await fetchAuthSession();
      const credentials = session.credentials;

      // Configurar Lambda
      const lambda = new AWS.Lambda({
        region: "us-east-1", // Cambia a tu región
        credentials: new AWS.Credentials(credentials.accessKeyId, credentials.secretAccessKey, credentials.sessionToken),
      });

      // Configurar los parámetros de la solicitud a la Lambda
      const params = {
        FunctionName: "binaria_sqlserver_to_Lambda",
        InvocationType: "RequestResponse", 
        Payload: JSON.stringify({ key: "value" }), 
      };

      const response = await lambda.invoke(params).promise();
      console.log("Respuesta de Lambda:", JSON.parse(response.Payload));
      setShowMessage(true);

    } catch (error) {
      console.error("Error al ejecutar la Lambda:", error);
    } finally {
      setLoading(false);  
    }

    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setLoading(false);
    }, 10000); 
  };

  
  return (
    <div className="contenedor-document">

      <div className="contenedor-botones">
        {/* Botón de sincronización que ejecuta la Lambda */}
        <button
          type="button"
          className="btn-submit"
          onClick={handleFullSync}
          disabled={loading}
        >
          {loading ? `Sincronizando... ${counter}s` : "Sincronización FULL"}
        </button>
        <small>Esta función se recomienda solo cuando se necesite sincronizar <strong>todos</strong> los productos. Si solo deseas actualizar, por favor utilizar <i>Actualizar productos</i></small>

      </div>

      {showMessage && <MessageSync onClose={() => setShowMessage(false)} />}
    </div>
  );
};
export default Sync;
